
import Utils from 'tradingmate-modules/src/Utils'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ContentEditableField from '@/components/forms/ContentEditableField.vue'

export interface AutocompleteResult {
  Label: string;
  Value: string;
}

export interface AutocompleteProvider<TResult extends AutocompleteResult> {
  Search(term: string): Promise<TResult[]>;
  Suggest(term: string): Promise<string | null>;
}

@Component({
  components: {
    ContentEditableField
  }
})
export default class AutocompleteField extends Vue {
  @Prop({ required: true })
  private readonly provider!: AutocompleteProvider<AutocompleteResult>;

  @Prop({ default: 1000 })
  private readonly debounceTimeout!: number;

  @Prop({ default: 'Search' })
  private readonly placeholder!: string;

  @Prop({ default: 'Search' })
  private readonly prompt!: string;

  private focused = false;

  private term = '';

  private suggestion = '';

  private debounce: number | undefined = undefined

  private debounceCallbackId = ''

  private results: AutocompleteResult[] = []

  select (result: AutocompleteResult): void {
    this.$emit('selected', result)

    this.focused = false
  }

  setFocus (): void {
    this.focused = true

    requestAnimationFrame(() => {
      (this.$refs.input as HTMLInputElement).focus()
    })
  }

  suggestor: (() => void) | null = null

  suggestionResult = ''

  updateSuggestion (): void {
    const loweredTerm = this.term.toLowerCase()
    const loweredResult = this.suggestionResult.toLowerCase()

    this.suggestion = loweredResult.split(loweredTerm)[1]
  }

  lastSuggestedTerm = '';

  @Watch('term')
  handleTermUpdated (): void {
    window.clearTimeout(this.debounce)

    if (this.suggestor === null) {
      this.suggestor = Utils.Throttle(150, () => {
        if (this.term.trim() === this.lastSuggestedTerm) return

        this.lastSuggestedTerm = this.term.trim().toLocaleLowerCase()

        this.provider.Suggest(this.term).then((result) => {
          if (!result) {
            this.suggestionResult = ''
            return
          }

          const loweredResult = result.trim().toLowerCase()

          if (loweredResult.indexOf(this.lastSuggestedTerm) !== 0) {
            this.suggestionResult = ''
            return
          }

          this.suggestionResult = loweredResult

          this.updateSuggestion()
        })
      })
    }

    this.suggestor()

    if (this.suggestionResult) this.updateSuggestion()

    this.debounce = setTimeout(() => {
      const callbackId = Utils.GetId()
      this.debounceCallbackId = callbackId

      this.provider.Search(this.term).then((results) => {
        if (callbackId !== this.debounceCallbackId) {
          return
        }

        this.results = results
      })
    }, this.debounceTimeout)
  }
}
