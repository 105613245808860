
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class ContentEditableField extends Vue {
  @Prop()
  private readonly placeholder!: string;

  @Prop()
  private readonly value!: string;

  private val = this.value ?? '';

  @Watch('val')
  updateData (): void {
    this.$emit('input', this.val)
  }

  handleInput (e: InputEvent): void {
    const target = e.target as HTMLDivElement

    this.val = target.innerText
  }

  focus (): void {
    (this.$refs.input as HTMLDivElement).focus()
  }
}
