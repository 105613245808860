
import Utils from 'tradingmate-modules/src/Utils'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

export interface AutocompleteDropdownResult {
  Label: string;
  Value: string;
}

export interface AutocompleteDropdownProvider<TResult extends AutocompleteDropdownResult> {
  // $route: any;
  Search(term: string): Promise<TResult[]>;
}

@Component
export default class AutocompleteDropdownField extends Vue {
  private readonly id = Utils.GetId();

  @Prop({ default: false })
  private readonly required!: boolean;

  @Prop()
  private readonly label!: string;

  @Prop({ default: 'Search' })
  private readonly placeholder!: string;

  @Prop({ default: 'No Results' })
  private readonly noResultsText!: string;

  @Prop({ default: 'Please enter at least 3 letters' })
  private readonly noTermText!: string;

  @Prop({ default: 3 })
  private readonly minTermLength!: number;

  @Prop({ required: true })
  private readonly provider!: AutocompleteDropdownProvider<AutocompleteDropdownResult>;

  @Prop({ default: 1000 })
  private readonly debounceTimeout!: number

  @Prop({ default: 3 })
  private readonly valueLimit!: number;

  private focused = false;

  private term = '';

  private results: AutocompleteDropdownResult[] = []

  private value: AutocompleteDropdownResult[] = []

  suggestor: (() => void) | null = null

  get maxValues (): boolean {
    return this.value.length <= (this.valueLimit - 1)
  }

  @Watch('value')
  private handleValuesChanged (): void {
    this.$emit('changedValues', this.value)
  }

  @Watch('term')
  private handleTermUpdated (): void {
    if (this.term.length < this.minTermLength) {
      this.results = []
      return
    }

    if (this.suggestor === null) {
      this.suggestor = Utils.Throttle(150, () => {
        this.provider.Search(this.term).then((result) => {
          if (!result) {
            this.results = []
            return
          }

          this.results = result
        })
      })
    }

    this.suggestor()
  }

  private select (itm: AutocompleteDropdownResult): void {
    this.value.push(itm)

    this.focused = false

    this.term = ''
  }

  private handleDeselect (value: string): void {
    const idx = this.value.findIndex((v) => v.Value === value)

    if (idx !== -1) this.value.splice(idx, 1)
  }
}
