
import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'
import { Services } from 'tradingmate-modules/src/services'
import { Vue, Component, Prop } from 'vue-property-decorator'
import AutocompleteDropdownField, { AutocompleteDropdownProvider, AutocompleteDropdownResult } from './AutocompleteDropdownField.vue'

interface ZoneAutocompleteResult extends AutocompleteDropdownResult {
  Zone: ZoneModel;
}

@Component({
  components: {
    AutocompleteDropdownField
  }
})
export default class ZoneSelection extends Vue {
  private provider: AutocompleteDropdownProvider<ZoneAutocompleteResult> = {
    async Search (term: string): Promise<ZoneAutocompleteResult[]> {
      if (term.length < 3) return []
      return (await Services.API.Zones.Autocomplete(term)).map((zone) => {
        return {
          Label: zone.TMName,
          Value: zone.ZoneId,
          Zone: zone
        }
      })
    }
  }

  @Prop({ default: 3 })
  private readonly valueLimit!: number;
}
