
import CategorySelector from '@/components/collections/category/CategorySelector.vue'
import ZoneSelector from '@/components/collections/zones/ZoneSelector.vue'
import InputText from '@/components/inputs/InputText.vue'
import InputTextarea from '@/components/inputs/InputTextarea.vue'
import { FormPage } from '@/mixins'
import { ZoneModel } from 'tradingmate-modules/src/models/api/admin'
import { BusinessBranchModel } from 'tradingmate-modules/src/models/api/businesses'
// import { CategoryModel } from 'tradingmate-modules/src/models/api/categorisation'
import CreateQuoteRequestModel from 'tradingmate-modules/src/models/api/quotes/CreateQuoteRequestModel'
import { Services } from 'tradingmate-modules/src/services'
import { Component, Mixins } from 'vue-property-decorator'
import AutocompleteField from '@/components/forms/AutocompleteField.vue'
import Form from '@/components/forms/Form.vue'
import FormField from '@/components/forms/FormField.vue'
import ZoneSelection from '@/components/forms/ZoneSelection.vue'
import Cards from '@/components/layout/Cards.vue'
import MemberCard from '@/components/collections/member/MemberCard.vue'
import ContentRow from '@/components/layout/ContentRow.vue'
import ReCaptcha from '@/components/inputs/ReCaptcha.vue'
import BusinessBranchOrCategorySearchResult
  from '../../../tradingmate-modules/src/models/api/search/BusinessBranchOrCategorySearchResult'
import Config from '@/config'

@Component({
  methods: {
    Config () {
      return Config
    }
  },
  components: {
    ReCaptcha,
    Form,
    FormField,
    InputText,
    InputTextarea,
    AutocompleteField,
    ZoneSelection,
    CategorySelector,
    ZoneSelector,
    Cards,
    MemberCard
  }
})

export default class GetAQuote extends Mixins(FormPage) {
  private createQuoteRequestModel: CreateQuoteRequestModel = {
    FullName: '',
    Email: '',
    PhoneNumber: '',
    CategoryId: '',
    ZoneId: '',
    ServiceRequired: '',
    AdditionalInformation: '',
    ReCaptchaToken: ''
  }

  private categories: BusinessBranchOrCategorySearchResult[] = []
  private zones: ZoneModel[] = []

  private branchesQuoteRequestsReceived: BusinessBranchModel[] | null = null

  onCatInput (): void {
    console.log(this.categories)
  }

  submitForm (): void {
    this.createQuoteRequestModel.ZoneId = this.zones[0]?.ZoneId ?? ''
    this.createQuoteRequestModel.CategoryId = this.categories[0]?.Id ?? ''
    this.loading = true
    this.httpError = null
    console.log(this.createQuoteRequestModel)
    Services.API.Quotes.CreateQuoteRequest(this.createQuoteRequestModel)
      .then((returnModels) => {
        this.branchesQuoteRequestsReceived = returnModels
        var top = this.$refs.top as ContentRow
        top.$el.scrollIntoView({ behavior: 'smooth', block: 'end' })
      })
      .catch((error) => { this.httpError = error })
      .finally(() => { this.loading = false })
  }

  resetQuoteRequest (): void {
    this.createQuoteRequestModel = {
      FullName: '',
      Email: '',
      PhoneNumber: '',
      CategoryId: '',
      ZoneId: '',
      ServiceRequired: '',
      AdditionalInformation: '',
      ReCaptchaToken: ''
    }
    this.categories = []
    this.zones = []
    this.branchesQuoteRequestsReceived = null
  }
}
